<template>
  <div class="media-slide">
    <ImgComponent :img="data.img" />
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "MediaSlideComponent",
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.media-slide {
  display flex
  align-items center
  justify-content center
  width 100%
  height 100%
	cursor: pointer
}
</style>
